import { FormattedMessage, MessageDescriptor } from 'react-intl';

interface Props extends MessageDescriptor {
  values?: Record<string, React.ReactNode>;
}

const FormatMessage = ({ values = {}, ...props }: Props) => (
  <FormattedMessage
    {...props}
    values={{
      ...values,
      br: <br />,
    }}
  />
);

export default FormatMessage;
