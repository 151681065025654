import React from 'react';
import { useSearchParams } from 'react-router-dom';
import Home from '../Home';
import { Language } from '../../components/LangList';
import { DEF_LANGUAGE } from '../../constants';
import { translations, TranslationProvider } from '../../i18n';
import './style.css';

// TODO - read language from browser setting rather than hard-coding:

function App() {
  const [searchParams] = useSearchParams();
  const selectedLang = (searchParams.get('lang') || DEF_LANGUAGE) as Language;

  return (
    <div className="App">
      <TranslationProvider language={selectedLang} translations={translations}>
        <Home />
      </TranslationProvider>
    </div>
  );
}

export default App;
