import React from 'react';
import './style.scss';

const VoiceTool = () => {
    return (
        <div className="voice-tool">
            <div id="eh-voice-tool-container"></div>
        </div>
    )
};

export default VoiceTool;
