import { useCallback, useMemo } from 'react';
import { CDN_URL, SITE_URL } from '../constants';

let isLoaded = false;
let loadLibPromise: Promise<void>;

const loadLibrary = () => {
    if (isLoaded || loadLibPromise) {
        return loadLibPromise;
    }

    loadLibPromise = new Promise<void>((resolve) => {

        isLoaded = true;

        const script = document.createElement('script');
        script.async = false;
        script.defer = false;
        script.src = `${CDN_URL}/libs/eh-web-lib-3.latest.min.js`;

        document.head.appendChild(script);

        script.onload = () => {
            resolve();
        };
    });

    return loadLibPromise;
};

type StartSessionArg = Pick<InitSessionConfig,
    'url' |
    'onStart' |
    'onScore' |
    'onError' |
    'onEnd' |
    'loadingGifUrl' |
    'loadingScreenEnabled' |
    'screenSize' |
    'skipSplash' |
    'queryParams'
>;

const useSession = (containerId: string) => {
    const libReadyPromise = useMemo(() => loadLibrary(), []);

    const start = useCallback(({
        url,
        onStart,
        onScore,
        onError,
        onEnd,
        loadingGifUrl,
        loadingScreenEnabled,
        screenSize,
        skipSplash,
        queryParams,
    }: StartSessionArg) => {
        console.log(libReadyPromise);
        libReadyPromise.then(() => {
            window.EHWEB.initSession({
                url: url || SITE_URL,
                queryParams,
                containerId,
                loadingGifUrl,
                loadingScreenEnabled,
                screenSize,
                skipSplash,
                jwt: '',
                onScore,
                onStart,
                onError,
                onEnd,
            });
        });
    }, [containerId, libReadyPromise]);

    return { startSession: start };
};

export default useSession;
