import React from 'react';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import MicNoneOutlinedIcon from '@mui/icons-material/MicNoneOutlined';
import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined';
import { FormatMessage } from '../../i18n';
import { RECORDING_DURATION_IN_SEC, RECORDING_DURATION_IN_MIN } from '../../constants';
// import ImgRecordUrl from '../../assets/img/record-1.png';
// import ImgTimerUrl from '../../assets/img/timer-1.png';
// import ImgTopicUrl from '../../assets/img/topic-1.png';

const INSTRUCTIONS_LIST = [
    {
        id: 'findQuietPlace',
        imgUrl: null, // ImgTopicUrl,
        Icon: ArticleOutlinedIcon,
        title: <FormatMessage id="home.instructions.findQuietPlace.title" />,
    },
    {
        id: 'record60Secs',
        imgUrl: null, // ImgTimerUrl,
        Icon: TimerOutlinedIcon,
        title: (
            <FormatMessage
                id="home.instructions.record60Secs.title"
                values={{
                    recordingDurationInSec: RECORDING_DURATION_IN_SEC,
                    recordingDurationInMin: RECORDING_DURATION_IN_MIN,
                }}
            />
        ),
    },
    {
        id: 'receiveInsights',
        imgUrl: null, // ImgRecordUrl,
        Icon: MicNoneOutlinedIcon,
        title: <FormatMessage id="home.instructions.receiveInsights.title" />,
    },
];

type Props = {
    onGetStarted: () => void;
};

const Instructions: React.FC<Props> = ({ onGetStarted }) => {
    return (
        <div className="instructions">
            <div className="container">
                <h3 className="title">
                    <FormatMessage id="home.instructions.title" />
                </h3>
                <div className="instruction__list">
                    {
                        INSTRUCTIONS_LIST.map((item) => (
                            <div className="instruction__item" key={item.id}>
                                <div className="instruction__icon">
                                    {
                                        item.imgUrl
                                            ? <img src={item.imgUrl} alt="" />
                                            : <item.Icon sx={{ fontSize: '3.5rem', color: 'var(--color-primary)' }} />
                                    }
                                </div>
                                <h4 className="instruction__title">{item.title}</h4>
                            </div>
                        ))
                    }
                </div>
                <div className="section-get-started hidden mobile-visible">
                    <button className="button button--solid" onClick={onGetStarted}>
                        <FormatMessage id="home.header.button.title" />
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Instructions;