import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import classnames from 'classnames';
import { Language } from '../../components/LangList';
import Footer from './Footer';
import Header from './Header';
import Instructions from './Instructions';
import MediaBox from './MediaBox';
import { useSession, useQueryParams, useDeviceTypeBySize } from '../../hooks';
import { DEF_LANGUAGE, PARTNER_ID } from '../../constants';
import './style.scss';

const Home = () => {
    let [searchParams, setSearchParams] = useSearchParams();
    const { refId, lang: selectedLang = DEF_LANGUAGE } = useQueryParams();
    const [score, setScore] = useState<ScoreMessage | undefined>();
    // const selectedLang = useMemo(() => searchParams.get('lang') as Language, [searchParams]);
    // const [error, setError] = useState<boolean>(false);

    const deviceType = useDeviceTypeBySize();

    const [voiceToolState, setVoiceToolState] = React.useState<null | 'started' | 'finished'>(null);

    // load the library and get the reference
    const session = useSession('eh-voice-tool-container');

    useEffect(() => {
        if (voiceToolState) {
            return;
        }

        const handleFinish = () => {
            setVoiceToolState('finished');

            document.body.classList.remove('voice-tool-started');
            document.body.classList.add('voice-tool-finished');

            document.querySelector('.voice-tool-area')?.scrollIntoView({
                behavior: 'smooth',
            });
        };

        session.startSession({
            // url: 'http://localhost:3000',
            loadingScreenEnabled: false,
            queryParams: {
                partnerId: PARTNER_ID,
                refId: refId || '',
                lang: selectedLang,
            },
            onStart: (...args) => {
                if (deviceType === 'desktop') {
                    setVoiceToolState('started');
                    document.body.classList.add('voice-tool-started');
                }
            },
            onScore: (...args) => {
                console.log('EHWEB > Score', ...args);
                handleFinish();
                setScore(args[0]);
            },
            onError: (...args) => {
                console.log('EHWEB > Error', ...args);
                handleFinish();
                // setError(true);
            },
        });
    }, [voiceToolState, deviceType, refId, session, selectedLang]);

    // callback to handle start event
    const getStartedHandler = React.useCallback(() => {
        if (voiceToolState === 'finished') {
            window.location.reload();
            return;
        }

        setVoiceToolState('started');
        document.body.classList.add('voice-tool-started');
    }, [voiceToolState]);

    const selectLangHandler = useCallback((lang: Language) => {
        setSearchParams({ lang });
    }, [setSearchParams]);

    // callback to handle setting language
    // TODO - pass param
    /**
    const setLangHandler = React.useCallback(() => {
        alert('setting lang');
    }, []);
    const setLangHandlerEn = React.useCallback(() => {
        alert('setting lang to en');
    }, []);
    class Square extends React.Component {
        render() {
          return (
            alert('setting lang to ' + this.props.value)
          );
        }
      }
    class Lang extends React.Component {
        set(locale) {
            return <Square value={locale} />
        }
    }
    const setLangHandlerJa = React.useCallback(() => {
        const widget = document.getElementById("eh-voice-tool-container")?.firstElementChild;
        alert('setting lang to ja');
    }, []);
    */

    return (
        <div className={classnames("home", "device-" + deviceType, voiceToolState && `voice-tool-${voiceToolState}`)}>
            <Header
                onGetStarted={getStartedHandler}
                onSelectLang={selectLangHandler}
                selectedLang={selectedLang as Language}
            />
            <Instructions onGetStarted={getStartedHandler} />
            <MediaBox state={voiceToolState} scoreMessage={score as any} />
            <Footer />
        </div>
    );
};

export default Home;

