import React from 'react';
import classNames from 'classnames';
import './style.scss';

const LangTexts = {
    en: 'English',
    ja: '日本',
};

export type Language = keyof typeof LangTexts;

type Props = {
    langs: Array<Language>;
    selected: Language;
    onSelect?: (val: Language) => void;
};

const addUrlParameter = (name: string, value: string) => {
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set(name, value);
    return `?${searchParams.toString()}${window.location.hash}`;
};

// NOTE: onSelect, selected is commented since this not being used but would come into picture when we do not want to reload

const LangList: React.FC<Props> = ({
    langs,
    selected,
    // onSelect,
}) => {

    // const onClick = (e: React.MouseEvent, lang: Language) => {
    //     e.preventDefault();

    //     if (typeof onSelect === 'function') {
    //         onSelect(lang);
    //     }
    // };

    return (
        <div className="lang-list">
            {langs.map((lang) => (
                <a
                    className={classNames('button', 'button--sm', lang === selected && 'button--selected')}
                    key={lang}
                    href={addUrlParameter('lang', lang)} /* onClick={(e) => onClick(e, lang)} */
                >
                    {LangTexts[lang]}
                </a>
            ))}
        </div>
    )
};

export default LangList;