const useQueryParam = () => {
    const url = new URL(window.location.href);

    const entries = url.searchParams.entries();
    const result: Record<string, string> = {};

    for (let entry of entries) {
        result[entry[0]] = entry[1];
    }

    return result;
};

export default useQueryParam;
