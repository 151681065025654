import React, { useMemo } from 'react';
import { FormatMessage } from '../../../i18n';


interface ResultProps {
    result?: ScoreMessage;
    error: boolean;
}

type AssessmentResult = {
    stress: {
        score: number;
        level: number;
    };
    anxiety: {
        score: number;
        level: number;
    };
    depression: {
        score: number;
        level: number;
    };
};

const Results: React.FC<ResultProps> = ({
    result,
    error,
}) => {
    const [isSuccess, scores] = useMemo<[false] | [true, AssessmentResult]>(() => {
        if (error || !result) {
            return [false];
        }

        const assessmentResult = {
            stress: {
                score: result.data.score?.measures?.stressScore ?? -1,
                level: 0,
            },
            anxiety: {
                score: result.data.score?.measures?.anxietyScore ?? -1,
                level: 0,
            },
            depression: {
                score: result.data.score?.measures?.depressionScore ?? -1,
                level: 0,
            },
        };

        if (assessmentResult.anxiety.score === -1) {
            // set default values if actual score is not available
            assessmentResult.anxiety.score = 4;
            assessmentResult.anxiety.level = 0;
        } else {
            // assign level from score
            if (assessmentResult.anxiety.score <= 4) {
                assessmentResult.anxiety.level = 0;
            } else if (assessmentResult.anxiety.score <= 9) {
                assessmentResult.anxiety.level = 1;
            } else if (assessmentResult.anxiety.score <= 14) {
                assessmentResult.anxiety.level = 2;
            } else {
                assessmentResult.anxiety.level = 3;
            }
        }

        if (assessmentResult.depression.score === -1) {
            // set default values if actual score is not available
            assessmentResult.depression.score = 3;
            assessmentResult.depression.level = 0;
        } else {
            // assign level from score
            if (assessmentResult.depression.score <= 4) {
                assessmentResult.depression.level = 0;
            } else if (assessmentResult.depression.score <= 9) {
                assessmentResult.depression.level = 1;
            } else if (assessmentResult.depression.score <= 19) {
                assessmentResult.depression.level = 2;
            } else {
                assessmentResult.depression.level = 3;
            }
        }

        if (assessmentResult.stress.score === -1) {
            // set default values if actual score is not available
            assessmentResult.stress.score = 12;
            assessmentResult.stress.level = 0;
        } else {
            // assign level from score
            if (assessmentResult.stress.score <= 13) {
                assessmentResult.stress.level = 0;
            } else if (assessmentResult.stress.score <= 26) {
                assessmentResult.stress.level = 1;
            } else {
                assessmentResult.stress.level = 2;
            }
        }

        return [true, assessmentResult];
    }, [error, result]);

    return (
        <div className="results">
            {(!isSuccess) && (
                <div className="error">
                    <h3>
                        <FormatMessage id="home.results.failureNote" />
                    </h3>
                </div>
            )}
            {isSuccess && (
                <div className="success">
                    <div className="note">
                        <FormatMessage id="home.results.successNote" />
                    </div>
                    <div className={`stress-result level-${scores?.stress.level}`}>
                        <h4>
                            <FormatMessage id="home.results.labels.stress" />
                        </h4>
                        <h1>{scores?.stress.score}</h1>
                    </div>
                    <div className={`anxiety-result level-${scores?.anxiety.level}`}>
                        <h4>
                            <FormatMessage id="home.results.labels.anxiety" />
                        </h4>
                        <h1>{scores?.anxiety.score}</h1>
                    </div>
                    <div className={`depression-result level-${scores?.depression.level}`}>
                        <h4>
                            <FormatMessage id="home.results.labels.depression" />
                        </h4>
                        <h1>{scores?.depression.score}</h1>
                    </div>
                </div>
            )}
            {/* <div className="cta-container">
                <button className="button">
                    I am Done
                </button>
            </div> */}
        </div>
    );
}

export default Results;
