const useDeviceTypeBySize = () => {
    return (
        window.matchMedia('(max-width: 768px)').matches
            ? 'mobile'
            : 'desktop'
    );

};

export default useDeviceTypeBySize;
