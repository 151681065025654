import React, { useMemo } from 'react';
import { IntlProvider, type ResolvedIntlConfig } from 'react-intl';
import flat from 'flat';
import i18n from './translations';

type Language = 'en'|'ja'; // TODO - make it accept en or ja

interface Props {
  children: React.ReactNode;
  translations: Record<Language, Record<string, any>>;
  language: Language;
}

const TranslationProvider = ({ children, language, translations }: Props) => {
  const messages: ResolvedIntlConfig['messages'] = useMemo(() => flat(
    (translations && (translations[language] || translations.en || translations.ja))
      || i18n[language]
      || i18n.en
      || i18n.ja,
  ), [translations, language]);

  return (
    <IntlProvider
      locale={language}
      messages={messages}
    >
      {children}
    </IntlProvider>
  );
};

export default TranslationProvider;
