import React from 'react';
import './style.scss';

const SOCIAL_MENUS = [
    {
        title: 'Twitter',
        img: <i className="fab fa-twitter-square"></i>,
        link: 'https://twitter.com/EllipsisHealth?s=20',
    },
    {
        title: 'LinkedIn',
        img: <i className="fab fa-linkedin"></i>,
        link: 'https://www.linkedin.com/company/ellipsis-health/',
    },
    {
        title: 'Youtube',
        img: <i className="fab fa-youtube-square"></i>,
        link: 'https://www.youtube.com/channel/UCIbmPilEMML0Hh3VhQ7GAoQ',
    },
];

const SocialMenuList = () => {
    return (
        <div className="social-menu-list">
            {SOCIAL_MENUS.map((menu) => (
                <div className="social-menu-list__item" title={menu.title} key={menu.title}>
                    <a href={menu.link}>
                        {menu.img}
                    </a>
                </div>
            ))}
        </div>
    )
};

export default SocialMenuList;
