export const ENV_TO_FB_PROJECT_ID = {
  dev: 'rising-higher-dev',
  qa: 'rising-higher-qa',
  demo: 'rising-higher-demo',
  prod: 'ellipsis-app-kittreskin',
};

export const ENV_TO_CDN_URL = {
  dev: 'https://cdn-dev.ellipsishealth.net',
  qa: 'https://cdn-qa.ellipsishealth.net',
  demo: 'https://cdn-demo.ellipsishealth.net',
  prod: 'https://cdn.ellipsishealth.net',
};

export const FIREBASE_PROJECT_ID = (
  ENV_TO_FB_PROJECT_ID[process.env.REACT_APP_ENV] || ENV_TO_FB_PROJECT_ID.dev
);

export const CDN_URL = ENV_TO_CDN_URL[process.env.REACT_APP_ENV] || ENV_TO_CDN_URL.dev;

export const ENV_TO_SITE_URL = {
  // dev: 'http://localhost:3000',
  dev: 'https://voice-collection-widget-dev.web.app',
  qa: 'https://voice-collection-widget-qa.web.app',
  demo: 'https://eh-optum-widget-demo.ellipsishealth.net',
  prod: 'https://eh-optum-widget.ellipsishealth.net',
};

export const SITE_URL = ENV_TO_SITE_URL[process.env.REACT_APP_ENV] || ENV_TO_SITE_URL.dev;

export const APP_WIDTH = 375;
export const APP_HEIGHT = 812;

export const PARTNER_ID = process.env.PARTNER_ID || 'NTT_AT';

export const RECORDING_DURATION_IN_SEC = 60;
export const RECORDING_DURATION_IN_MIN = Math.ceil(RECORDING_DURATION_IN_SEC / 60);

export const DEF_LANGUAGE = 'ja';
// export const lang='ja'
// export const lng='ja'
